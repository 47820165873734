.contRowOptions{
	text-align: right;
	position: relative;
}
.MuiTableRow-root:hover .contRowOptions{
	display: block;
}
.contRowOptions .dropdown{
	/* position: absolute; */
	right: 0;
	top: -16px;
}
.contRowOptions.inline{
    display: inline-block !important;
    width: 30px !important;
    margin-right: 5px
}
.contRowOptions.inline .btnRowOptions{
    right: 0 !important;
    top: 0 !important;
}
.contRowOptions .dropdown-toggle{
	background: #fff;
	width: 30px;
	height: 30px;
    padding: 0;
    border-radius: 50px;
    outline: none;
    border: none;
    -webkit-box-shadow: 0px 2px 3px 0px rgba(0, 0, 0, 0.18) !important;
	-moz-box-shadow:    0px 2px 3px 0px rgba(0, 0, 0, 0.18) !important;
	box-shadow:         0px 2px 3px 0px rgba(0, 0, 0, 0.18) !important;
	z-index: 99;
	position: absolute !important;
    right: 50% !important;
}
.contRowOptions .dropdown-toggle:before{
	content: "...";
	color: #a6a6a6;
    font-size: 16px;
    letter-spacing: 2px;
    line-height: 13px;
    vertical-align: top;
    margin-left: 2px;
}
.contRowOptions .dropdown-toggle:after{
	display: none;
}
.contRowOptions .dropdown-toggle[aria-expanded="true"]{
	background: #ff8d6a !important;
}
.contRowOptions .dropdown-toggle[aria-expanded="true"]:before{
	content: '\E82E';
    font-family: icons;
    color: #ffffff;
    font-size: 12px;
    letter-spacing: 2px;
    line-height: 20px;
    vertical-align: top;
    margin-left: 2px;
}
.dropdown-menu{
	top: 0px !important;
    right: 0px !important;
    z-index: 9;
    border: 0;
	-webkit-box-shadow: 0px 8px 18px 0px rgba(37, 62, 145, 0.07);
	-moz-box-shadow:    0px 8px 18px 0px rgba(37, 62, 145, 0.07);
	box-shadow:         0px 8px 18px 0px rgba(37, 62, 145, 0.07);
}
.dropdown-menu .dropdown-item{
	font-size: 14px;
	color: #1e3c5d;
}
.dropdown-menu .dropdown-item:hover{
	background: none;
	color: #ff8d6a;
}
.dropdown-menu.show{
	z-index: 999 !important
}