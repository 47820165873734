@import url('https://fonts.googleapis.com/css2?family=Rubik:wght@300;400;500;600&display=swap');

body{
	font-family: 'Rubik', sans-serif;
	background: #f1f7fc;
}
.container{
    max-width: 1440px;
    position: relative;
}
a{
    color: #3a9bfd;
    text-decoration: none;
}
header{
    padding-top: 30px;
    padding-bottom: 70px;
    background: url("bgHeader.jpg");
    h1{
        margin-top: 47px;
        display: block;
		color: #fff;
		font-size: 30px;
        font-weight: 400;   
	}
	.navbar{
		padding-top: 0;
		padding-bottom: 0;
        .nav-link{
            background: #162f4a;
            color: #fff !important;
            font-size: 14px;
            padding: 10px 20px !important;
            margin-right: 20px;
            border-radius: 50px;
            &.active, &:hover{
                background: #ff7f00;
            }
        }
        .justify-content-end{
            margin-top: -22px;
        }
    }
    .navbar-brand img{
        min-height: 54px;
    }
}
.dropdown-item.active, .dropdown-item:active{
    color: #ff7f00 !important;
    background-color: #fff !important;
}
#userProfile{
            background: none;
            color: #fff !important;
            font-size: 14px;
            padding: 10px 0 !important;
            margin-left: 30px;
            border:0;
}
.mainBox{
	background: #fff;
	margin-top: -50px;
	padding: 2.5%;
	border-radius: 10px;
	-webkit-box-shadow: 7px 7px 7px 0px rgba(0, 2, 0, 0.05);
	-moz-box-shadow:    7px 7px 7px 0px rgba(0, 2, 0, 0.05);
	box-shadow:         7px 7px 7px 0px rgba(0, 2, 0, 0.05);
}
.subBox{
    border: 1px solid #dbdbdb;
	padding: 2% 2% 0 2%;
	border-radius: 10px;
}
.subBox .MuiTable-root{
    margin-bottom: 2%;
}
.form-group{
    margin-bottom: 30px;
    label{
        display: block;
        font-size: 14px;
        color: #1c1c1c;
        margin-bottom: 5px;;
    }
    input[type='text'], input[type='email'],  input[type='password'], input[type='date'], input[type='number'], select, textarea{
        width: 100%;
        border: 1px solid #dbdbdb;
        border-radius: 5px;
        padding: 10px !important;
        font-size: 14px;
        height: 45px;
        &:focus{
            border: 2px solid #229bfd;
        }
    }
    &.error{
        // label{
        //     color: #ee8a8a;
        // }
        // input[type='text'], select{
        //     border: 2px solid #ee8a8a;
        // } 
        .errorField{
            color: #f75454;
            font-size: 14px;
            line-height: 36px;
        }
    }
}
.errorFieldForm{
    color: #f75454;
    font-size: 14px;
    margin-top: -20px;
    line-height: 36px;
}
strong{
    margin-bottom: 10px;
    display: block;
}
.MuiPaper-elevation2{
    box-shadow: none !important;
}

.transitionPage-enter {
  opacity: 0;
}
.transitionPage-enter-active {
  opacity: 1;
  transition: opacity 500ms;
}
.transitionPage-exit {
  opacity: 1;
}
.transitionPage-exit-active {
  opacity: 0;
  transition: opacity 500ms;
}
.titleButtons{
    margin-top: -70px;
    position: absolute;
    right: 0;
    .nav-link{
        border: 1px solid #229bfd;
        color: #229bfd !important;
        font-size: 14px;
        padding: 10px 20px !important;
        margin-right: 20px;
        cursor: pointer;
        border-radius: 50px;
        &:hover{
            background: #229bfd;
            color: #fff !important;
        }
    }
    .dropdown-item{
        padding: 0;
        color: #229bfd !important;
    }
}
.titleButtonsTable{
    position: absolute;
    z-index: 99;
    .nav-link{
        border: 1px solid #229bfd;
        color: #229bfd !important;
        font-size: 14px;
        padding: 10px 20px !important;
        margin-right: 20px;
        cursor: pointer;
        border-radius: 50px;
        &:hover{
            background: #229bfd;
            color: #fff !important;
        }
    }
    .dropdown-item{
        padding: 0;
        color: #229bfd !important;
    }
}
.btnAdd{
    display: block;
    border: 1px solid #229bfd;
    color: #229bfd !important;
    font-size: 14px;
    padding: 10px 20px !important;
    margin-right: 20px;
    cursor: pointer;
    border-radius: 50px;
    max-width: max-content;
    &:hover{
        background: #229bfd;
        color: #fff !important;
    }
}
.secondaryButton{
    border: 1px solid #229bfd;
    color: #229bfd !important;
    background: none;
    font-size: 14px;
    padding: 10px 20px !important;
    margin-right: 20px;
    border-radius: 50px;
    cursor: pointer;
    &:hover{
        background: #229bfd;
        border: 1px solid #229bfd;
        color: #fff !important;
    }
}
.buttonSubmit{
        color: #fff !important;
        font-size: 14px;
        border: 0;
        background: #ff7f00;
        padding: 13px 30px !important;
        margin-right: 20px;
        border-radius: 50px;
        margin: 30px auto 0;
        display: block;
        &:hover{
            background: #229bfd;
            color: #fff !important;
        }
}

.buttonSubmit:disabled {
    background: #a1a1a1;
    cursor: not-allowed;
}

.buttonSubmit:disabled:hover {
    background-color: #a1a1a1;
}

// Table
.MuiTable-root{
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    overflow: hidden;
    .MuiTableHead-root{
        .MuiTableRow-head{
                .MuiTableCell-head{
                    background: #ff7f00;
                    color: #fff;
                    font-weight: 500;
                    text-transform: uppercase;
                    font-size: 13px;
                    padding-left: 20px !important;
                    padding-top: 12px !important;
                    padding-bottom: 12px !important;
                    font-family: 'Rubik', sans-serif;
            }
        }       
    }
    .MuiTableBody-root{
        border-left: 1px solid #ecedf4;
        border-right: 1px solid #ecedf4;
        border-bottom: 1px solid #ecedf4;
        .MuiTableRow-root{
            .MuiTableCell-body{
                font-family: 'Rubik', sans-serif !important;
                color: #1c1c1c !important;
                font-size: 13px !important;
                border-bottom: 1px solid #ecedf4 !important;
            }
        }
    }
}
.MuiToolbar-regular {
    min-height: 15px !important;
}
.MuiPaper-root{
    .MuiToolbar-root{
        padding-right: 0;
        .MuiTextField-root{
            background: #ffff !important;
            border: 1px solid #e5e5e5 !important;
            border-radius: 50px !important;
            padding: 5px 0 5px 14px !important;
            margin-bottom: 20px;
        }
        .MuiInput-underline:after, .MuiInput-underline:before{
            display: none !important;
        }
        .MuiInput-underline{
            .MuiInputAdornment-root{
                .material-icons{
                    color: #c9c9c9;
                }
            }
            .MuiInputBase-input{
                font-size: 13px;
                color: #525252;
            }
        }
        
    }
    .MuiTablePagination-selectRoot{
        display: none;
    }
    .MuiTableCell-footer{
        border-bottom: 0;
    }
}

// Alerts
.alert{
    overflow: hidden;
    p{
        opacity: 0;
        animation: myAnimbackwards 1s ease 0s 1 normal backwards;
    }
    &.show{
        p{
            opacity: 1;
            animation: myAnim 1s ease 0s 1 normal forwards;
        }
    }
    &.success{
        p{
            color: #6fd105;
            font-weight: 500;
            line-height: 11px;
            font-size: 14px;        
        }
    }
    &.fail{
        p{
            color: rgb(251, 131, 131);
            font-weight: 500;
            line-height: 11px;
            font-size: 14px;        
        }
    }
}
.btnBack{
    color: #fff;
    border-radius: 50px;
    padding: 3px;
    border: 1px solid #fff;
    margin-right: 20px;
    vertical-align: -2px;
}
.is_dependent{
    display: block;
}
.is_dependent.hideField, .is_dependent.showSection{
    display: none;
}
@keyframes myAnim {
    0% {
      opacity: 0;
      transform: translateX(50px);
    }
  
    100% {
      opacity: 1;
      transform: translateX(0);
    }
  }
@keyframes myAnimbackwards {
0% {
    opacity: 1;
    transform: translateX(0);
}

100% {
    opacity: 0;
    transform: translateX(50px);
}
}
.MuiTableCell-root > div > span{
    display: none;
}
.modal-body p strong{
    display: inline-block;
}
.modal-footer button{
    color: #fff !important;
    font-size: 14px;
    border: 0;
    background: #ff7f00;
    padding: 13px 30px !important;
    border-radius: 50px;
    margin:  auto;
    display: block;
    &:hover{
        background: #229bfd;
        color: #fff !important;
    }
}
.react-datepicker__header{
    background: #fff !important;
  }
  .react-datepicker__header a{
        appearance: button;
      -webkit-writing-mode: horizontal-tb !important;
      text-rendering: auto;
      color: -internal-light-dark(black, white);
      letter-spacing: normal;
      word-spacing: normal;
      text-transform: none;
      text-indent: 0px;
      text-shadow: none;
      display: inline-block;
      text-align: center;
      align-items: flex-start;
      cursor: default;
      box-sizing: border-box;
      margin: 0em;
      font: 400 13.3333px Arial;
      padding: 14px 6px;
      border-image: initial;
  }
  .react-datepicker__header select{
     padding: 0 10px !important;    
     margin: 0 3px;
  }
  .react-datepicker__day--selected, .react-datepicker__day--in-selecting-range, .react-datepicker__day--in-range, .react-datepicker__month-text--selected, .react-datepicker__month-text--in-selecting-range, .react-datepicker__month-text--in-range, .react-datepicker__quarter-text--selected, .react-datepicker__quarter-text--in-selecting-range, .react-datepicker__quarter-text--in-range, .react-datepicker__year-text--selected, .react-datepicker__year-text--in-selecting-range, .react-datepicker__year-text--in-range{
    background-color: #80bd01;
    border-radius: 50px;
  }

  .elementType span{
    padding: 1px 10px 1px 10px;
    border-radius: 50px;
    color: #a1a1a1;
    display: block !important;
}
.elementType.active span{
  color: #80bd01;
}
.elementType.active span:before{
  content: "";
  width: 6px;
  height: 6px;
  background: #80bd01;
  border-radius: 50px;
  display: inline-block;
  margin-right: 5px; 
  vertical-align: top;
  margin-top: 7px;  
}

.elementType.disabled span{
  color: #e46139;
}
.elementType.disabled span:before{
  content: "";
  width: 6px;
  height: 6px;
  background: #e46139;
  border-radius: 50px;
  display: inline-block;
  margin-right: 5px; 
  vertical-align: top;
  margin-top: 7px;  
}
.marginBottom80{
    margin-bottom: 80px;
}
.modal-header{
    border: none;
    padding: 2rem 2rem 0 2rem;
}
.modal-title{
    font-size: 20px;
    color: #ff7f00;
    font-weight: 400;
}
.modal-body{
    padding: 1rem 2rem;
}
.modal-footer{
    border: none;
    padding: 0rem 2rem 2rem 2rem;
}
.reportsBox{
    padding: 30px 30px 15px 30px;
}
.reportsBox h4{
    color: #ff7f00;
    font-size: 19px;
    font-weight: 400;
}
.reportsBox ul{
    list-style: none;
    padding: 0;
}
.reportsBox button{
    color: #1e3c5d;
    font-size: 15px;
    margin-bottom: 5px;
    background: none;
    padding: 0;
    border: 0;
    display: block;
    cursor: pointer;
}
.reportsBox button:hover{
    text-decoration: underline;
}
.reportsBox img{
    margin-bottom: 10px;
    width: 50px;
}
.title{
    font-size: 20px;
    color: #ff7f00;
    font-weight: 400;
}
.MuiToolbar-root.MTableToolbar-highlight-6{
    display: none !important;
}
.select__value-container{
    min-height: 46px;
}
.select__input-container{
    height: 38px;
}
.select__placeholder{
    height: 23px;
}
.select__input-container input.select__input{
    padding:0 !important;
    height: 35px !important;
}
.conteo{
    font-size: 14px;
    color: #a1a1a1;
}
.MuiToolbar-gutters{
    padding-left: 0 !important;
}
.MTableToolbar-title-9{
    position: absolute;
    top: 0;
}
.numeroActividad{
    display: block;
    font-size: 16px;
    margin-left: 52px;
    font-style: normal;
    margin-right: 20px;
}
.beneficiariesDetail{
    margin-bottom: 20px;
    border: 1px solid #e5e5e5;
    border-radius: 10px;
    padding: 15px 14px 0 14px;
}
.beneficiariesDetail p{
    font-size: 14px;
    color: #686868;
    margin-bottom: 0;
}
.beneficiariesDetail p strong{
    display: block;
    font-weight: 500;
    margin-bottom: -5px;
}
.beneficiariesDetailContent{
    margin-bottom: 20px;
}
.beneficiaries_list .MuiButtonBase-root.MuiIconButton-root.MuiIconButton-colorInherit[type='button']{
    margin-top: -24px !important;
    margin-left: 10px !important;
}

.multiSelect input[type=text] {
    padding: 0 !important;
}

.actividadesHeader{
    width: calc(100% - 430px);
    padding-left: 50px;
  }
  .actividadesHeader a {
    left: 13px;
    position: absolute;
  }
  .numeroActividad{
    margin-top: 10px;
    margin-left: 0;
  }

.tableDates, .tableDates tr{
    border: 1px solid #ccc;
  }
.segregado{
    display: flex;
    align-items: center;
    gap: 20px;
} 
.segregado label{
    width: 15%;
    text-align: right;
}
.segregado > input, .segregado .multiSelect, .segregado .react-datepicker-wrapper{
    width: 30% !important;
}
.matrizTable td {
    padding-right: 10px;
    padding-top: 10px;
    padding-bottom: 10px;
}
.matrizTable td label.options{
    margin-bottom: 0;
    display: flex;
    text-align: right;
    flex-direction: column;
    align-items: center;
    width: 110px;
}
.matrizTable td input{
    margin: 0 auto 5px;
}
.matrizTable tr{     
    border-bottom: 1px solid #ccc;
}
.matrizTable tr td:first-child{
    padding-right: 50px;
    min-width: 150px;
} 
.matrizTable tr th{
    padding: 0 10px;
    font-weight: normal;
    font-size: 14px;
}